import React, { useState, useEffect } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import ApiListaExterno from '../../service/ApiListaExterno';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Grid from "@mui/material/Unstable_Grid2";
import 'primereact/resources/themes/saga-blue/theme.css';
//import 'primereact/resources/primereact.css';

export default function ListaExterno() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [nodes, setNodes] = useState([{}]);
  const [saldo, setSaldo] = useState(0);

  const [service, setService] = useState(new ApiListaExterno());

  useEffect(() => {
    buscar();
  }, []);

  const buscar = () => {

    service
      .consultar()
      .then(resposta => {

        const lista = resposta.data;

        setNodes(lista);
 
   
      }).catch(error => {
        console.log(error)
      });

      console.log(nodes);


  }

  return (

    <div>

      <div style={{ margin: 10 }}>
        <h2>Almoxarifado a</h2>
         <Button  onClick={((e) => buscar())} variant="contained">Contained</Button>

      </div>

      <TreeTable tableStyle={{ minWidth: '50rem' }} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} removableSort value={nodes} scrollable scrollHeight="650px" style={{ margin: 10 }} onScroll={true} >

        <Column sortable field="saldo" headerStyle={{ fontSize: 12, color: colors.grey[100], backgroundColor: colors.primary[400] }} header="Saldo" expander style={{ fontSize: 10, backgroundColor: colors.primary[400], color: colors.grey[100] }}></Column>
    
      </TreeTable>

    </div>

  );
}