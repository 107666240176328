import ApiServiceProd from '../service/ApiServiceProd';

import ErroValidacao from '../validacao/index';

class ApiEmpresaService extends ApiServiceProd {

    constructor(){ 
        super('/api/empresa')
    }
  
    salvar(empresa){  
        return this.post('', empresa);
    }
   

    validar(empresa){
        const erros = []

        if(!empresa.descempr){
            erros.push('O campo Nome é obrigatório.')
        }
   

        if(erros && erros.length > 0){
            throw new ErroValidacao(erros);
        }
    }

}

export default ApiEmpresaService;