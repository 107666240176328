import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";
import ApiLinkExterno from "./ApiLinkExterno";

export default class ApiBuscaCep extends ApiLinkExterno {

    constructor(){
        super('api/cep/') 
               
    } 

    consultar(filtro){ 

        let params = `${filtro.cep}`
 
        return this.get(params);
    } 
 
}