import React, { useState, useEffect } from 'react';
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import ApiListaEmpresaService from '../../service/ApiListaEmpresas';

import Header from "../../components/Header";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]); // Data for DataGrid
  const [service, setService] = useState(new ApiListaEmpresaService());

  const columns = [
    { field: "codempr", headerName: "Id", width: 100 },
    { field: "cnpj", headerName: "Registrar Id", width: 100 },
    { field: "descempr",headerName: "Name",cellClassName: "name-column--cell",width: 200,},
    { field: "cep",headerName: "Age", headerAlign: "left",align: "left",width: 100,},
    { field: "email", headerName: "Email", width: 200 }, 
  ];


  useEffect(() => {
    buscar();
  }, []);

  const buscar = () => {

    service
      .consultar()
      .then(resposta => {
  
        setData(resposta.data); 

      }).catch(error => {
        console.log(error)
      });

  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="CONTATO" subtitle="bem vindo a você Contatos" />
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Contacts;
