import ApiUsuarioService from '../service/ApiUsuarioService'; 

export default class LogarUsuario extends ApiUsuarioService {

    constructor(){
        super('consecana/logon/usuario/')  
    }    

    consultar(filtro){ 

        let params = `${filtro.email}`

        if(filtro.email){
            params = `${params}/${filtro.senha}`
        } 
        return this.get(params);
    } 
 
}