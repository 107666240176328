import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'

import { AuthConsumer } from './service/ProvedorAutenticacao';

import Login from "./pages/login";
import Login2 from "./pages/login2";

import Dashboard from "./pages/dashboard";
import Team from "./pages/team";
 
import Invoices from "./pages/invoices";
import Contacts from "./pages/contacts";
import PosicaoEstoque from "./pages/posicaoestoque";
import EstoquesemMov from './pages/estoquesemmov';
import EstoqueObsoleto from "./pages/estoqueobsoleto";
import EstoqueExterno from "./pages/estoqueexterno";
import ExternoSaldo from './pages/externo';

import Form from "./pages/form";
import Empresa from "./pages/empresa";
import Calendar from "./pages/calendar";
import Bar from "./pages/bar";
import Line from "./pages/line";
import Pie from "./pages/pie";
import FAQ from "./pages/faq";
import CadastroUsuario from "./pages/cadastroUsuario"; 
import AlteraUsuario from "./pages/alteraSenha";

function RotaAutenticada({ component: Component, isUsuarioAutenticado, ...props }) {

    return (
        <Route exact {...props} render={(componentProps) => {
            if (isUsuarioAutenticado) {
                return (
                    <Component {...componentProps} />
                )
            } else {
                return (
                    <Redirect to={{ pathname: '/', state: { from: componentProps.location } }} />
                )
            }
        }} />
    )
}

function Rota(props) { 
    return (

        < >
            <Switch>
                <Route exact path="/" component={Login} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/dashboard" component={Dashboard} />
                <Route  path="/cadastrousuario" component={CadastroUsuario} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/alterausuario" component={AlteraUsuario} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/posicaoestoque" component={PosicaoEstoque} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/estoquesemmov" component={EstoquesemMov} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/estoqueobsoleto" component={EstoqueObsoleto} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/estoqueexterno" component={EstoqueExterno} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/login2" component={Login2} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/externo" component={ExternoSaldo} />

                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/contacts" component={Contacts} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/invoices" component={Invoices} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/form" component={Form} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/bar" component={Bar} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/pie" component={Pie} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/line" component={Line} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/faq" component={FAQ} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/calendar" component={Calendar} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/form" component={Form} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/empresa" component={Empresa} />

            </Switch>
            </ >


    );
};
 
export default () => (
    <AuthConsumer>
        {(context) => (<Rota isUsuarioAutenticado={context.isAutenticado} />)}
    </AuthConsumer>
)
