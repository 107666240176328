import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";
import ApiLinkExterno from "./ApiLinkExterno";
import ApiBuscaSaldoService from "./ApiBuscaSaldoService";

export default class ApiBuscaSaldo extends ApiBuscaSaldoService {

    constructor(){
        super('api/externa') 
               
    } 

    consultar(){  
 
         return this.get_geral();
    } 
 
}
 