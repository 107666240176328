import React from "react";

import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../service/ProvedorAutenticacao';

import { LockOutlined } from "@mui/icons-material";

import { toast, ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

import {
    Container,
    CssBaseline,
    Box,
    Avatar,
    Typography,
    TextField,
    Button,
    Grid,
    duration
} from "@mui/material";

import { Link, useHistory } from "react-router-dom";

import UsuarioService from "../../usuario";
import LogarUsuario from '../../logar';

class Login extends React.Component {

    state = {
        email: '',
        senha: '',

    }

    constructor() {
        super();
        this.service = new UsuarioService();
        this.serviceLogar = new LogarUsuario();

    }

    entrar = () => {

        if (this.state.email.length == 0) {
            toast.error("Campo email não pode ficar em branco.", { autoClose: 1500 });
            return false;
        }
        if (!this.state.senha) {
            toast.error("Campo senha não pode ficar em branco.", { autoClose: 1500 });
            return false;
        }

        this.service.autenticar({
            email: this.state.email,
            senha: this.state.senha,      
        }).then(response => {

            const textForStorage = this.state.email
            console.log("usuario " + textForStorage);
            localStorage.setItem('usuario', textForStorage);

            // getter  
            this.context.iniciarSessao(response.data);

            // this.props.history.push('/dashboard')
            this.props.history.push('/empresa')
        }).catch(erro => {
            toast.error(erro.response.data, { autoClose: 1500 });

        })

    }

    cadastreempresa = () => {

        // this.props.history.push('/dashboard')
        this.props.history.push('/cadastrousuario') 

    }

    render() {
        return (
            <>

                <Container maxWidth="xs">

                    <ToastContainer position="top-right" duration />

                    <Box m="164px 0 0 0"
                        width="60%"
                        height="40vh"
                    >
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h5">Fazer login e-mail e senha</Typography>
                        </Box>

                        <Box sx={{ mt: 0 }} >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                variant="filled"
                                //     autoFocus
                                value={this.state.email}
                                onChange={e => this.setState({ email: e.target.value })}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="senha"
                                name="senha"
                                label="Senha"
                                variant="filled"
                                type="password"
                                value={this.state.senha}
                                onChange={e => this.setState({ senha: e.target.value })}
                            />

                            <Box display="flex" justifyContent="left">
                                <div className="signup-link">
                                    <p>
                                        Esqueceu a senha ? <a href="/cadastrousuario">Clica aqui</a>{" "}
                                    </p>
                                </div>
                            </Box>

                            <Box display="flex" justifyContent="center" >
                                <Button style={{ width: 200 }} color="secondary" variant="contained" onClick={this.entrar}>
                                    Login
                                </Button>
                            </Box>

                            <Box display="flex" justifyContent="center">
                                <div  >
                                    <p>
                                        Não tem uma conta?
                                        <Button style={{ width: 50 }}   variant="contained" onClick={this.cadastreempresa}>
                                            Registrar
                                        </Button>
                                    </p>
                                </div>
                            </Box>

                        </Box>
                    </Box>
                </Container>
            </>
        )

    }
}

Login.contextType = AuthContext

export default withRouter(Login)

