import React, { useState, useEffect } from 'react'
import UsuarioService from '../../service/ApiUsuarioService';
import { tokens } from "../../theme";

import * as messages from '../../components/toastr'

import { useHistory } from 'react-router-dom';

import {
    Container,
    CssBaseline,
    Box,
    useTheme,
    Avatar,
    Typography,
    TextField,
    Button,
    Grid,
    duration
} from "@mui/material";


import { toast, ToastContainer } from "react-toastify";
import Header from "../../components/Header";
 
function CadastroUsuario() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [senhaRepeticao, setSenhaRepeticao] = useState('');
    const [service, setService] = useState(new UsuarioService());

    let history = useHistory();

    const cadastrar = () => {

        const usuario = { 
            email: email,
            senha: senha,
            senhaRepeticao: senhaRepeticao
        }

    //    try {
    //        service.validar(usuario);
   //     } catch (erro) {
  //          const msgs = erro.mensagens;
  //          msgs.forEach(msg => toast.error(msg));
  //          return false;
  //      }

        try {
            service.salvar(usuario)
                .then(response => {
                    toast.error('Usuário cadastrado com sucesso! Faça login para acessar o sistema.');
                    history.push('/')
                }).catch(erro => {
                    toast.error(erro.response.data);
                })
        } catch (erro) {

        }

    }

    const login = () => {
        history('/login');
    }
  
    const handleChangeemail = (event) => {
        setEmail(event.target.value);
    };

    const handleChangesenha = (event) => {
        setSenha(event.target.value);
    };

    const handleChangesenhaRepeticao = (event) => {
        setSenhaRepeticao(event.target.value);
    };

    return (
        <div>

            <ToastContainer position="top-right" duration={1000} />

            <Container maxWidth="xs">

                <Box
                    m="32px 0 0 0"
                    width="100%"
                    height="80vh"
                    sx={{


                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .name-column--cell": {
                            color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },

                    }}
                >

                    <Header title="CADASTRAR USUÁRIO" subtitle="Criação usuário novo" />
 

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        variant="filled"
                        name="email"
                        value={email}
                        onChange={handleChangeemail}
                    />

                    <TextField
                        margin="normal"
                        required
                        variant="filled"
                        fullWidth
                        id="senha"
                        name="senha"
                        label="Senha"
                        type="password"
                        value={senha}
                        onChange={handleChangesenha}
                    />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        variant="filled"
                        id="inputRepitaSenha"
                        name="senha"
                        label="Repete Senha"
                        type="password"
                        value={senhaRepeticao}
                        onChange={handleChangesenhaRepeticao}

                    />
   
                    <Box display="flex" justifyContent="end" mt="20px">
                        <Button  color="secondary" variant="contained" onClick={cadastrar}>
                            Criar usuário
                        </Button>
                    </Box>

                </Box>
            </Container>
        </div>

    )


}

export default CadastroUsuario;