import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";
import ApiLinkExterno from "./ApiLinkExterno";

export default class ApiListaExterno extends ApiLinkExterno {

    constructor(){
        super('estoque/almox/externo') 
               
    } 

    consultar(Filtro){

        return this.get_geral();
    }
 
} 