import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";

import Topbar from "./pages/global/Topbar";

import ProvedorAutenticacao from "./service/ProvedorAutenticacao";
import Rota from "./rota";
import Login from "./pages/login";

function App() {
  const [theme, colorMode] = useMode(); 

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ProvedorAutenticacao>
        <MyProSidebarProvider>
            <div style={{ height: "100%", width: "100%" }}>
              <main>
                <Topbar />
                <div>
                  <Rota />
                </div>
              </main>
            </div>
          </MyProSidebarProvider>
          </ProvedorAutenticacao>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};


export default App;
