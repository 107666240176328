import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";  

export default class ApiListaEmpresas extends ApiServiceReinfProd {

    constructor(){
        super('nfe/lista/empresa') 
               
    } 

    consultar(){

        return this.get_geral();
    }
 
}