import React, { useState, useEffect } from 'react'
import EmpresaService from '../../service/ApiEmpresaService';
import ApiBuscaSaldo from '../../service/ApiBuscaSaldo';
import ApiBuscaCep from '../../service/ApiBuscaCep';

import { tokens } from "../../theme";

import * as messages from '../../components/toastr'

import { useHistory } from 'react-router-dom';

import {
  Container,
  CssBaseline,
  Box,
  useTheme,
  Avatar,
  IconButton,
  Typography,
  TextField,
  Button,
  Grid,
  duration
} from "@mui/material";

import { toast, ToastContainer } from "react-toastify";
import Header from "../../components/Header";
import { PersonSharp } from '@mui/icons-material';

import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';

function Empresa() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [descempr, setDescempr] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [service, setService] = useState(new EmpresaService());
  const [serviceSaldo, setServiceSaldo] = useState(new ApiBuscaSaldo());
  const [serviceCep, setServiceCep] = useState(new ApiBuscaCep());
  const [nodes, setNodes] = useState([{}]);
  const [regSaldo, setRegSaldo] = useState([{}]);
  const [usuario, setUsuario] = useState(localStorage.getItem('usuario'));

  const maskGenerator = createDefaultMaskGenerator('99.999.999-9999.99');
  const [value, setValue] = useState('');

  const cadastrar = () => {

    const empresa = {
      descempr: descempr,
      cnpj: cnpj,
      cep: cep,
      email: email,
      usuario: usuario,
    }

    try {

      service.salvar(empresa)
        .then(response => {
          toast.success("Empresa cadastrada com sucesso.");
        }).catch(erro => {
          toast.error(erro.response.data);

        })
    } catch (erro) {

    }

  };

  const saldo = () => {

    try {
 
      serviceSaldo.consultar()
        .then(response => {

          console.log("antes");

          const lista = response.data;

          setRegSaldo(response.data);

          console.log("saldo 1 -> ");

          lista.forEach(function (registro, index) {

            console.log("saldo 2 -> " + registro.deck_id); 

          });

        }).catch(erro => {
       //   toast.error(erro.response.data, { autoClose: 1500 });
          console.log("erro " + erro.response);
        })
    } catch (erro) {

    }

  };

  const buscacep = () => {

    const ccep = '15800010';

    const filtro = {
      cep: ccep
    }

    try {

      serviceCep.consultar(filtro)
        .then(response => {

          const lista = response.data;

          setNodes(response.data);

          lista.forEach(function (registro, index) {

            console.log("cep -> " + registro.cep);

          });

        }).catch(erro => {
          toast.error("erro -> " + erro);

        })
    } catch (erro) {

    }

  };

  const handleChangecnpj = (event) => {
    setCnpj(event.target.value);
  };

  const handleChangedescempr = (event) => {
    setDescempr(event.target.value);
  };

  const handleChangecep = (event) => {
    setCep(event.target.value);
  };

  const handleChangeemail = (event) => {
    setEmail(event.target.value);
  };

  return (

    <div>

      <IconButton >
        <PersonSharp />
        <div style={{ fontSize: 10, paddingLeft: 5 }}> {usuario}</div>
      </IconButton>

      <ToastContainer position="top-right" duration={1000} />

      <Container maxWidth="xs"  >

        <Box
          m="32px 0 0 0"
          width="100%"
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },

          }}
        >
          <Header title="CRIAÇÃO DA EMPRESA" subtitle="Criar uma nova empresa" />

          <TextField
            margin="normal"
            required
            fullWidth
            variant="filled"
            id="cnpj"
            label="CNPJ"
            name="cnpj"
            //  autoFocus
            value={cnpj}
            onChange={handleChangecnpj}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            variant="filled"
            id="descempr"
            label="Razão Social"
            name="descempr"
            value={descempr}
            onChange={handleChangedescempr}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            variant="filled"
            id="cep"
            label="Cep"
            name="cep"
            value={cep}
            onChange={handleChangecep}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            variant="filled"
            id="email"
            label="E-Mail"
            name="email"
            value={email}
            onChange={handleChangeemail}
          />

          <Box display="flex" justifyContent="end" mt="20px">
            <Button color="secondary" variant="contained" onClick={cadastrar}>
              Criar empresa
            </Button>
          </Box>

          <Box display="flex" justifyContent="end" mt="20px">
            <Button color="secondary" variant="contained" onClick={saldo}>
              Busca Saldo
            </Button>
          </Box>


          <Box display="flex" justifyContent="end" mt="20px">
            <Button color="secondary" variant="contained" onClick={buscacep}>
              Busca cep
            </Button>
          </Box>

        </Box>
      </Container>
    </div>

  )

}

export default Empresa;